.content-carousel-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 48px;

  .carousel-placeholder {
    height: 200px;
    display: flex;
    align-items: center;

    .no-contents {
      color: #474747;
      font-weight: 300;
    }
  }

  .content-carousel {
    display: inline-flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: 24px;
  }
}

.scroller {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;

  & > div {
    cursor: pointer;
    background-color: #FEEAE2;
    width: 30px;
    height: 30px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:hover {
      background-color: #f8dbcf;
    }
  }
}
