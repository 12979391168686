$ap-image-path: '~/node_modules/@appkit4/styles/images';
$ap-font-path: '~/node_modules/@appkit4/styles/font';
$ap-font-icon-path: '~/node_modules/@appkit4/styles/font-icon';

@import '../node_modules/@appkit4/styles/scss/index.scss';
@import '../node_modules/@appkit4/styles/scss/themes/orange.theme';

ap-header {
  a, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
  }

  .ap-header {
    background-color: transparent !important;
    position: sticky;
    top: 0;
  }
}

.ap-progress-stepper-wrapper {
  width: 100%;

  .ap-progress-stepper {
    flex-grow: 1;
  }
}

.ap-modal-wrapper:has(.confirm-dialog) {
  z-index: 2050;
}

.ap-modal-wrapper:has(.tutorial-dialog) {
  .ap-modal.ap-modal-static {
    min-width: min(95%, 900px);
    max-width: clamp(900px, 40%, 90vw);
    min-height: min(100%, 565px);

    .ap-modal-body {
      display: flex;
      flex-grow: 1;
    }
  }
}

.ap-modal-wrapper:has(.information-request-modal) {
  .ap-modal-title {
    width: 75%;
  }
  .ap-field .ap-datepicker-wrapper {
    position: fixed;
  }
}

.custom-header .ap-modal-header {
  display: inline-flex;
  justify-content: start;
  flex-direction: row-reverse;
  gap: 0.5rem;

  .ap-modal-title {
    margin: 0;
  }
}

modal {
  .ap-single-dropdown-list.open, .ap-dropdown-list.open {
    position: sticky;
  }
}

.ap-field-input {
  color: #252525 !important;
}

.ap-timepicker-input, .ap-radio-label, .ap-option-label {
  opacity: 1 !important;
  color: #252525 !important;
}

.ap-modal-footer-customize {
  width: 100%;
  justify-content: flex-end;
}

.ap-notifications {
  z-index: 2100 !important;
}

.ap-avatar img {
  object-fit: cover;
}

div [role="dialog"] {
  min-width: min(95%, 540px);
  max-width: clamp(540px, 40%, 90vw);

  &:has(.cs-body) {
    max-width: clamp(540px, 80%, 90vw);
  }

  .ap-modal-body {
    min-height: 92px;
  }

  .ap-modal-footer {
    padding-top: 8px; margin-top: -8px; min-height: 64px;
  }
}

.fixed-dialog .ap-modal-body {
  overflow: visible;
}

.ap-progress-stepper {
  outline: none;
}

.ap-notifications {
  z-index: 9999 !important;
}

.ap-avatar {
  padding: 0 !important;
}
