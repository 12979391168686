/*** Welcome to the styling section of a Project ***/

/* You can add global styles to this file, and also import other style files */

/** Bootstrap theming **/
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/utilities";

/** Appkit theming **/

@import "src/appkit.scss";
// We're gonna use ng-bootstrap, so don't worry about this, only if is necessary
// @import "~bootstrap/scss/functions";

/** Main colors for Appkit 4.0 **/
// $body-bg: whitesmoke;

/*
$tokens: (
    (color-background-primary, #FF2D55),
    (color-background-primary-code, #FF2D55)
);

:root {
  @include setColorToken($tokens);

  $tokens {
    --custom-primary: #415385FF;
    --custom-secondary: #9AA4BEFF;
    --custom-success: #22992EFF;
    --custom-danger: #FFBF1FFF;
    --custom-error: #C52A1AFF;
  }
}
*/

/* Theming group colors */
/*
$theme-colors: (
  "primary": var(--primary),
  "secondary": var(--secondary),
  "danger": var(--danger)
);

 */

/** Addons **/
// @import "../node_modules/ng2-slim-loading-bar/style.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";
// @import "../src/assets/styles/search-icon.scss";
// @import "../src/assets/styles/tables.scss";
// @import "../src/assets/styles/logos.scss";
// @import "../src/assets/styles/actions.scss";
// @import "../src/assets/styles/pagination.scss";
// @import "../src/assets/styles/forms.scss";

/** Other theming **/
// TODO: removed for now because it can cause theme mixing...
// @import "../src/assets/styles/required-label.scss";
// @import "../src/assets/styles/ng-select.scss";
// @import "../src/assets/styles/datepicker.scss";
// @import "../src/assets/styles/file-input.scss";
@import "colors";
@import "./_card.scss";
@import "./_frontoffice.scss";
@import "./_dashboard.scss";

/** Global theming **/
html,
body {
  font-size: 100%; // 16px
  vertical-align: baseline;
  align-items: stretch;
  border: 0;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.max-content {
  width: max-content;
}

.loader-wrapper {
  z-index: 2500;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  background-color: rgba(25, 25, 25, 0.32);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgb(208, 74, 2);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  color: $color-text-error;
  margin: $spacing-3 0 $spacing-4 $spacing-3;
}

#navbar {
  height: calc(100vh - 4rem);
  position: sticky;
  top: 4rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.state-circle {
  height: 12px;
  width: 12px;
  min-width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.chevron-transform {
  transform: rotate(-180deg);
}

.chevron-transition {
  transition: transform 0.3s ease;
}

.cover {
  max-width: 100%;
  max-height: 336px;
}

.modal-image {
  max-height: 150px;
}

.list-row {
  border-radius: $border-radius-2;
  padding: $spacing-3;
  cursor: pointer;

  &:hover {
    background-color: #e9e9e9;
  }
}

.list-image {
  max-width: 100%;
  height: 50px;
}

.h-scroll {
  scrollbar-width: auto !important;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $gray-400;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.v-scroll {
  scrollbar-width: thin;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &-1 {
    @extend .clamp;
    -webkit-line-clamp: 1;
  }

  &-4 {
    @extend .clamp;
    -webkit-line-clamp: 4;
  }
}

.mandatory {
  position: relative;
  width: fit-content;

  &::before {
    content: "";
    position: absolute;
    right: calc(-1* var(--spacing-3));
    top: var(--spacing-2);
    width: .25rem;
    height: .25rem;
    border-radius: var(--border-radius-1);
    background-color: var(--color-text-error);
  }
}

.component-card {
  @extend .simple-card;
  @extend .row-gap-2;
  width: 300px;
  cursor: pointer;
  transition: all 250ms;

  figure {
    max-width: 100%;
    height: 130px;
    overflow: hidden;
    border-radius: $border-radius-2;
    margin: 0;
  }

  img {
    height: 130px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    transform-origin: center;
    transition: transform 0.4s ease-in-out;
  }

  &:hover img,
  &:hover .img-placeholder {
    transform: scale(1.3);
  }
}

.img-placeholder {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-2;
  background-color: #e9e9e9;
  overflow: hidden;
  transform-origin: center;
  transition: transform 0.4s ease-in-out;

  span::before {
    width: 40px;
    font-size: 40px;
    color: $color-background-primary;
  }
}

.line-element-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #F3F3F3;
  padding: 1rem;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}

.contact-person-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 100%;
  min-height: 300px;
  border-radius: $border-radius-3;
  padding: 2rem;
  background-color: #EDEDED;
  text-align: center;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-picture-placeholder {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F3F3;
    overflow: hidden;

    span::before {
      width: 30px;
      font-size: 30px;
      color: $color-background-primary;
    }
  }
}

.hide {
  height: 0 !important;
}

.page .body-wrapper>* {
  display: block;
  min-width: 100%;
  width: fit-content;
}

.ap-footer-content {
  font-size: 1vh;
  color: white;
}

.ap-footer-link-goup {
  font-size: 1.3vh;
}