@import "./_content-carousel.scss";

$content-width: 1360px;

.hero {
  width: 100%;
  height: 500px;

  @media (max-width: 780px) {
    height: 300px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content-grid {
  height: max-content;
  flex: 0 0 100%;
  transition: all 0.25s ease-in-out;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(330px, 1fr));
  gap: 16px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 780px) {
    grid-template-columns: 1fr;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .content {
    position: relative;
    top: -32px;
    padding: 0 2rem;
    //width: clamp(200px, 80vw, 1360px);
    width: 100%;
    max-width: $content-width;
  }

  .grey-bg {
    width: 100%;
    padding: 32px 3rem;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F3F3F3;

    &__content {
      width: 100%;
      max-width: $content-width;
    }
  }
}

.even-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 300px;
  border-radius: $border-radius-3;
  padding: 2rem;
  background-color: #EDEDED;

  .date-time-wrapper {
    display: flex;
    gap: 2rem;
  }

  .icon-text-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 22px;
  }
}

.bordered-card {
  border-radius: 8px;
  border: 1px solid #F3F3F3;
  background-color: #FAFAFA;
  margin-top: 2rem;
  padding: 2.5rem 2.5rem 1.5rem;
}
