:root {
  --event-badge-color: rgb(38, 119, 109);
  --event-badge-color-bg: rgba(38, 119, 109, 0.1);
  --article-badge-color: rgb(144, 63, 77);
  --article-badge-color-bg: rgba(144, 63, 77, 0.1);
  --docs-badge-color: rgb(71, 71, 71);
  --docs-badge-color-bg: rgb(243, 243, 243);
  --yellow-badge-color: rgb(255, 191, 31);
  --yellow-badge-color-bg: rgb(255, 191, 31, 0.1);
  --gray: rgb(151, 151, 151);
  --gray-dark: rgb(98, 98, 98);
}

.gray {
  color: var(--gray);
}

.gray-dark {
  color: var(--gray-dark);
}

