.dashboard {
  .filters {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    flex-wrap: wrap;

    &>* {
      flex-grow: 1;
    }
  }

  .title {
    font-size: 1.2rem;
    font-weight: 500;
  }
}