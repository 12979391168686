@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";

.simple-card {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-3;
  box-shadow: $box-shadow-1;
  background-color: $color-background-container;
  padding: $spacing-5;

  &.gray {
    background-color: #FAFAFA;
    color: #252525;
  }
}
